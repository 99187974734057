import axios from "axios";
import { API_URL } from "./index";

export default
    {
        SaveBolParaCampaignResponse(token, campaignID, data) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/save-bol-para-campaign-response`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    campaignID: campaignID,
                    data: data
                }
            });
        },
        GetBolParaCampaign(token, campaignID) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/get-bolpara-campaign`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    campaignID: campaignID
                }
            });
        },
        GetBolParaCompanies(token) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/get-bol-para-companies`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
        },
    }